import { HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideRemoteConfig, getRemoteConfig } from '@angular/fire/remote-config';
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ToastrModule } from 'ngx-toastr';
import { LightboxModule } from 'ngx-lightbox';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Observable } from 'rxjs';
import { environment } from "environments/environment";

export class TranslateHttpLoader implements TranslateLoader {

  constructor(private http: HttpClient) {}

  getTranslation(lang: string): Observable<Object> {
    /*const localTranslation: Observable<Object> = this.http.request('GET', "/assets/i18n/" + lang + ".json", {responseType:'json'});
    const remoteTranslation: Observable<Object> = this.http.request('GET', environment.i18n.url + lang + environment.i18n.namespace, {responseType:'json'});

    return forkJoin({
      remote: remoteTranslation.pipe(timeout(2000)),
      local: localTranslation
    }).pipe(map(translations => {
      return this.mergeTranslations(translations.local, this.formatObject(translations.remote));
    })).pipe(catchError(_ => localTranslation));*/

    return this.http.request('GET', "/assets/i18n/" + lang + ".json", {responseType:'json'});
  };

  formatObject(obj: any): object {
    const formattedObj: object = {};

    for (const key in obj) {
      const keys: string[] = key.split('.');

      keys.reduce((acc: object, k: string, i: number) => {
        if (i === keys.length - 1) {
          acc[k] = obj[key];
        } else {
          acc[k] = acc[k] || {};
        }

        return acc[k];
      }, formattedObj);
    }

    return formattedObj;
  };

  mergeTranslations(target: any, source: any): object {
    for (const key in source) {
      if (source.hasOwnProperty(key)) {
        if (source[key] instanceof Object && key in target) {
          Object.assign(source[key], this.mergeTranslations(target[key], source[key]));
        }
      }
    }

    Object.assign(target || {}, source);

    return target;
  }
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule,
    LightboxModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateHttpLoader,
        deps: [HttpClient]
      }
    }),
    FontAwesomeModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule,
    TooltipModule.forRoot(),
    PopoverModule.forRoot()
  ],
  providers: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideRemoteConfig(() => getRemoteConfig()),
    provideHttpClient(withInterceptorsFromDi()),
    { provide: 'REMOVE_STYLES_ON_COMPONENT_DESTROY', useValue: false },
    { provide: LOCALE_ID, useValue: 'fr' }
  ]
})
export class AppModule { }
