<router-outlet *ngIf="networkStatus && isApiOk && !maintenance"></router-outlet>

<div class="row vh-100" *ngIf="!networkStatus || !isApiOk || maintenance">
  <div class="col-10 offset-1 offset-lg-3 col-lg-6 card my-auto py-5">
    <div *ngIf="!networkStatus">
      <div class="text-center">
        <span class="offline-title" translate="offline.offline-title"></span><br><br>
        <span class="offline-text" translate="offline.offline-text"></span><br>
      </div>
    </div>

    <div *ngIf="!isApiOk && networkStatus && !maintenance">
      <div class="text-center">
        <img src="assets/logo/logo.svg" height="96" alt="logo"><br><br>
        <span class="offline-title" translate="offline.offline-server-title"></span><br><br>
        <span class="offline-text" translate="offline.offline-server-text"></span><br>
        <button class="btn btn-keytocheck w-50 mt-5" (click)="refresh()">
          <span *ngIf="refreshing" class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
          <span translate="offline.offline-server-retry-button"></span>
        </button>
      </div>
    </div>

    <div *ngIf="maintenance && networkStatus" class="row">
      <div class="text-center">
        <img src="assets/logo/logo.svg" height="96" alt="logo"><br><br>
        <span class="text-center col-12 mt-4 offline-title" translate="maintenance.maintenance-title"></span><br><br>
        <span class="text-center col-12 offline-text" [innerHTML]="maintenance_msg[userLang]"></span>
      </div>
    </div>
  </div>
</div>
