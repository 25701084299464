import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from "./guards/auth.guard";
import { AdminGuard } from "./guards/admin.guard";

const routes: Routes = [
  { path: 'checkin', loadChildren: () => import('./modules/checkin/checkin.module').then(m => m.CheckinModule) },
  { path: 'conciergerie/admin', loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard, AdminGuard] },
  { path: 'conciergerie', loadChildren: () => import('./modules/conciergerie/conciergerie.module').then(m => m.ConciergerieModule)},
  { path: 'user', loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule), canActivate: [AuthGuard] },
  { path: '', loadChildren: () => import('./modules/guest/guest.module').then(m => m.GuestModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
