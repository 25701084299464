import { Component, OnInit, inject, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from "@angular/router";
import { fetchAndActivate, getBoolean, getString, RemoteConfig } from '@angular/fire/remote-config';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, map, merge, of, Subscription } from "rxjs";
import { ApiService, ErrorResponse } from "app/services/api.service";
import { environment } from 'environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private remoteConfig: RemoteConfig = inject(RemoteConfig);

  title: string = 'KeyToCheck';
  userLang: string = 'en';
  maintenance: boolean = false;
  maintenance_msg: any = {};

  isApiOk: boolean = true;
  refreshing: boolean = false;
  networkStatus: boolean = false;
  networkStatusSubscription: Subscription = Subscription.EMPTY;
  routerSubscription: Subscription = Subscription.EMPTY;

  constructor(translate: TranslateService, private apiService: ApiService, private router: Router) {
    this.userLang = navigator.language;

    translate.addLangs(environment.langs);
    translate.setDefaultLang('en');

    if (this.userLang && this.userLang.length >= 2) {
      this.userLang = this.userLang.slice(0, 2)
    }

    if (localStorage.getItem('language')) {
      this.userLang = localStorage.getItem('language');
    }

    if (environment.langs.includes(this.userLang)) {
      translate.use(this.userLang);
      localStorage.setItem('language', this.userLang);
    }
    else {
      this.userLang = 'fr';
      translate.use('fr');
      localStorage.setItem('language', 'fr');
    }

    this.routerSubscription = router.events.subscribe((val): void => {
      if (val instanceof NavigationStart) {
        this.getRemoteConfig();
        this.checkApi();
      }
    });
  }

  ngOnInit(): void {
    this.getRemoteConfig();
    this.checkApi();
    this.checkNetworkStatus();
  }

  ngOnDestroy(): void {
    this.networkStatusSubscription?.unsubscribe();
    this.routerSubscription?.unsubscribe();
  }

  getRemoteConfig(): void {
    let env: string = (environment.production ? "": "_dev");
    this.remoteConfig.settings.minimumFetchIntervalMillis = 30000;

    fetchAndActivate(this.remoteConfig)
      .then((): void => {
        this.maintenance = getBoolean(this.remoteConfig, "maintenance" + env);
        this.maintenance_msg = JSON.parse(getString(this.remoteConfig, "maintenance_msg" + env));
      });
  }

  checkApi(): void {
    this.apiService.post("", {}, (_: any): void => {
      this.isApiOk = true;
      this.refreshing = false;
    }, (_: ErrorResponse): void => {
      this.isApiOk = false;
      this.refreshing = false;
    });
  }

  checkNetworkStatus(): void {
    this.networkStatus = navigator.onLine;

    this.networkStatusSubscription = merge(of(null), fromEvent(window, 'online'), fromEvent(window, 'offline'))
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        this.networkStatus = status;
      });
  }

  refresh(): void {
    this.refreshing = true;
    this.checkApi();
  }

}
