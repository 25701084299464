import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Conciergerie } from "../models/conciergerie.model";
import { UserService } from "../services/user.service";
import { ToastService } from "../services/toast.service";

@Injectable({
  providedIn: 'root'
})
export class AdminGuard  {

  private subscription: Subscription;
  private conciergerie: Conciergerie;
  private conciergerie_id: string = localStorage.getItem('conciergerie_id');

  constructor(private router: Router, private userService: UserService, private toastService: ToastService) {
    let conciergerieJson = localStorage.getItem('conciergerie');

    if (conciergerieJson) {
      let conciergerie = JSON.parse(conciergerieJson);
      if (conciergerie && conciergerie.id == this.conciergerie_id) {
        this.conciergerie = conciergerie;
      }
    }

    this.subscription = this.userService.getConciergerie().subscribe(conciergerie => {
      this.conciergerie = conciergerie;
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.conciergerie?.is_admin) {
      return true;
    }
    this.toastService.error('error.not-admin');
    this.router.navigate(['/conciergerie/home']);

    return false;
  }

}
